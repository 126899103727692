
.error-page {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  gap: 24px;
  background-color: #ececec;
  .middle-panel {
    .centerbox {
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 15px;
      text-align: center;

      img {
        width: 100%;
      }

      h2 {
        font-size: 30px;
        margin-top: 30px;
        text-transform: uppercase;
      }
      p {
        font-size: 18px;
        margin-top: 14px;
      }
      a.backhome {
        display: inline-block;
        background: #fa8c28;
        padding: 10px 15px;
        margin-top: 16px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
